import { api } from '../api';

const serviceUser = {};

serviceUser.enroll = (enrollmentId = false) => {
    if (!enrollmentId) {
        return api.post('/users/enroll');
    }
    return api.get(`/users/enroll/${enrollmentId}`);
};

serviceUser.updateSupportedStreamer = (streamerId) => {
    return api.put('/users/supportedStreamer', { streamerId });
};

serviceUser.checkIfUserIsPrime = () => {
    return api.post('/users/hasPrime');
};

serviceUser.get = () => {
    return api.get('/users');
};

export default serviceUser;
