<template>
    <button
        class="btn"
        type="submit"
        :style="{ width: buttonWidth }"
        :disabled="disabled || isLoading"
        ref="button"
    >
        <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
            :class="[isLoading ? '' : 'visually-hidden']"
        />
        <span :class="[isLoading ? 'visually-hidden' : '']" v-if="label">{{ label }}</span>
        <span :class="[isLoading ? 'visually-hidden' : '']" v-else><slot /></span>
    </button>
</template>

<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
    loading: {
        type: Boolean,
        required: false,
        default: false,
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false,
    },
    label: {
        type: String,
        required: false,
        default: null,
    },
});

const button = ref(null);
const buttonWidth = ref('auto');
const isLoading = ref(false);

watch(
    () => props.loading,
    () => {
        buttonWidth.value = `${button.value.offsetWidth}px`;
        isLoading.value = props.loading;
    }
);
</script>
