<template>
    <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header border-0 pb-0">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">Soutenir un streamer</h1>
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div class="modal-body">
                    En validant, vos prochains abonnements iront automatiquement à la chaîne Twitch
                    de Locklear.
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        Annuler
                    </button>

                    <Button
                        type="button"
                        class="btn btn-primary"
                        @click="updateSupportedStreamer"
                        :loading="isLoading"
                        >Je valide la validation</Button
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import Button from '@/components/core/button.vue';
import { ref } from 'vue';

const isLoading = ref(false);

const updateSupportedStreamer = async () => {
    console.log('ok');
    isLoading.value = true;
};
</script>
