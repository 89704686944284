import axios from 'axios';
import axiosRetry from 'axios-retry';
import config from '@/config';

axiosRetry(axios, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

const api = axios.create({
    baseURL: config.api_url,
});

api.interceptors.request.use((request) => {
    if (localStorage.getItem('token')) {
        request.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    }
    return request;
});

api.interceptors.response.use(
    (response) => {
        if (response.headers.authorization) {
            const token = response.headers.authorization.split(' ')[1];
            localStorage.setItem('token', token);
        }

        return response.data;
    },
    (error) => {
        if (error.response.status === 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('isLogged');
            window.location.replace('/login');
        }

        return Promise.reject(error.response);
    }
);

export { api };
