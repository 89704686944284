<template>
    <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
    >
        <div class="offcanvas-header">
            <div
                class="profilePicture"
                :style="`background-image:url('${userStore.userInfos.profile_image_url}')`"
            ></div>
            <div class="profileDisplayName">
                {{ userStore.userInfos.display_name }}
            </div>
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
            ></button>
        </div>
        <div class="offcanvas-body">
            <ul class="menu">
                <li data-bs-dismiss="offcanvas">
                    <router-link :to="{ name: 'search' }" class="nav-link">
                        <i class="bi bi-search"></i>
                        {{ $t('menuSearchStreamer') }}</router-link
                    >
                </li>
                <li data-bs-dismiss="offcanvas">
                    <router-link :to="{ name: 'contact' }" class="nav-link">
                        <i class="bi bi-envelope"></i>
                        {{ $t('menuContactUs') }}
                    </router-link>
                </li>
                <li data-bs-dismiss="offcanvas">
                    <router-link :to="{ name: 'logout' }" class="nav-link logout">
                        <i class="bi bi-box-arrow-right"></i>
                        {{ $t('menuLogout') }}
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup>
import { useUserStore } from '@/stores';

const userStore = useUserStore();
</script>

<style scoped>
.offcanvas {
    background: #171717 !important;
}
.offcanvas-header {
    color: #fff !important;
}
.profilePicture {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-size: 100%;
    background-color: #fff;
}
.profileDisplayName {
    font-size: 25px;
    font-weight: 500;
    margin-left: 15px;
}
.offcanvas {
    background-color: rgb(49, 51, 56);
}
.menu {
    list-style-type: none;
    padding: 0;
}

.menu li {
    padding: 10px;
    font-weight: 500;
}

.menu li a {
    color: /* not white */ #f8f9fa !important;
    text-decoration: none;
}
.menu li i {
    margin-right: 10px;
    font-size: 18px;
}
.menu li .logout {
    color: #dc3545 !important;
}
</style>
