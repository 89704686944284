import * as bootstrap from 'bootstrap/dist/js/bootstrap.bundle';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { createI18n } from 'vue-i18n';
import App from './App.vue';
import router from './router';
import messages from './translations.json';
import 'bootstrap-icons/font/bootstrap-icons.css';

const pinia = createPinia();

const userLanguage = localStorage.getItem('local') || navigator.language || navigator.userLanguage;
const locale = userLanguage.split('-')[0];

const i18n = createI18n({
    locale,
    fallbackLocale: 'en',
    messages,
});

const app = createApp(App);

app.use(i18n);
app.use(pinia);
app.use(router);
app.provide('bootstrap', bootstrap);
app.mount('#app');
