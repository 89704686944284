import { defineStore } from 'pinia';
import { useLocalStorage } from '@vueuse/core';

import ServiceUser from '@/services/service_user';
import ServiceCookies from '@/services/service_cookies';

export const useUserStore = defineStore('app', {
    state: () => ({
        isLoaded: false,
        isLogged: useLocalStorage('isLogged', false),
        token: useLocalStorage('token', null),

        userId: useLocalStorage('userId', null),
        userLocale: useLocalStorage('local', null),
        userInfos: false,
        userSupportedStreamer: false,
        userDates: false,

        enrollmentId: ServiceCookies.get('enrollmentId'),
        enrollmentCode: ServiceCookies.get('enrollmentCode'),

        getAuthTokenInterval: false,
    }),

    actions: {
        async init() {
            if (
                this.enrollmentId &&
                this.enrollmentCode &&
                (this.token === null || this.token === '')
            ) {
                await this.getAuthToken();
            }

            if (this.token) {
                await this.initUser();
            }

            this.isLoaded = true;
        },

        async getEnrollmentCode() {
            await ServiceUser.enroll()
                .then((enrollmentInfos) => {
                    ServiceCookies.set('enrollmentId', enrollmentInfos._id, 5);
                    ServiceCookies.set('enrollmentCode', enrollmentInfos.user_code, 5);
                    this.enrollmentId = enrollmentInfos._id;
                    this.enrollmentCode = enrollmentInfos.user_code;
                    this.getAuthToken();
                })
                .catch((e) => {
                    console.log(e);
                });
        },

        async getAuthToken() {
            if (this.getAuthTokenInterval) {
                clearInterval(this.getAuthTokenInterval);
                this.getAuthTokenInterval = false;
            }

            this.getAuthTokenInterval = setInterval(() => {
                ServiceUser.enroll(this.enrollmentId)
                    .then(async ({ access_token }) => {
                        if (access_token) {
                            clearInterval(this.getAuthTokenInterval);

                            this.token = access_token;

                            await this.initUser();

                            window.location.reload(true);

                            ServiceCookies.delete('enrollmentId');
                            ServiceCookies.delete('enrollmentCode');
                            this.enrollmentId = false;
                            this.enrollmentCode = false;
                        }
                    })
                    .catch(() => {
                        clearInterval(this.getAuthTokenInterval);

                        ServiceCookies.delete('enrollmentId');
                        ServiceCookies.delete('enrollmentCode');
                        this.enrollmentId = false;
                        this.enrollmentCode = false;
                    });
            }, 1000);
        },

        async updateSupportedStreamer(streamerId) {
            await ServiceUser.updateSupportedStreamer(streamerId).then((data) => {
                this.userSupportedStreamer = data.supported_streamer;
            });
        },

        async checkIfUserIsPrime() {
            return ServiceUser.checkIfUserIsPrime().then((data) => {
                this.userInfos = data.twitch;
                this.userSupportedStreamer = data.supported_streamer;

                console.log(data);
                return data.twitch.has_prime;
            });
        },

        async initUser() {
            await ServiceUser.get().then((data) => {
                this.isLogged = true;
                this.userId = data._id;
                this.userInfos = data.twitch;
                this.userDates = data.dates;
                this.userSupportedStreamer = data.supported_streamer;
            });
        },

        async setLocal(newLocal) {
            this.userLocale = newLocal;
        },
    },
});
