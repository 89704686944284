export default {
    get(name) {
        const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
        return match ? match[2] : null;
    },

    set(name, value, minutes) {
        const expires = new Date(Date.now() + minutes * 60 * 1000);
        document.cookie = `${name}=${value}; expires=${expires}; path=/`;
    },

    delete(name) {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    },
};
