<template>
    <template v-if="userStore.isLogged">
        <OffCanvasUserMenu />
        <ModalSupportStreamer />
    </template>

    <div class="row m-0 justify-content-center">
        <div class="col-lg-3 col-xs-12" style="max-width: 480px">
            <div class="row" v-if="userStore.isLoaded">
                <router-view />
            </div>

            <div class="row justify-content-center align-items-center vh-100" v-else>
                <div class="col-auto text-center">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>

            <div class="row mt-3 mb-5 pt-2 footer-text">
                <div class="col-6">v{{ config.version }}</div>
                <div class="col-6 text-end">
                    <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events -->
                    <span @click="changeLanguage()">{{ $t('switchLanguage') }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useUserStore } from '@/stores';
import { onMounted } from 'vue';
import config from '@/config';
import { useI18n } from 'vue-i18n';

import ModalSupportStreamer from '@/components/modals/modal_support_stremear.vue';
import OffCanvasUserMenu from '@/components/offcanvas/offcanvas_user_menu.vue';

const { locale } = useI18n();
const userStore = useUserStore();

const changeLanguage = () => {
    if (locale.value === 'fr') {
        locale.value = 'en';
    } else {
        locale.value = 'fr';
    }

    userStore.setLocal(locale.value);
};

onMounted(() => {
    userStore.init();
});
</script>

<style>
/* html,
body {
    height: 100%;
    touch-action: manipulation;
    background: rgb(92, 114, 225);
    background: linear-gradient(
        45deg,
        rgba(92, 114, 225, 1) 0%,
        rgba(109, 115, 229, 1) 55%,
        rgba(178, 118, 242, 1) 100%
    );
    background-attachment: fixed;
} */

html,
body {
    touch-action: manipulation;
    background-color: #171717 !important;
    min-height: 100vh;
}
:root {
    --bs-primary: red;
}

.btn {
    border-radius: 4px;
}

.btn-primary {
    background-color: #5664f0 !important;
    border-color: #5664f0 !important;
    font-weight: 500 !important;
    border-radius: 5px !important;
}
.btn-primary:hover {
    background-color: #4d73e3;
    border-color: #4d73e3;
}
.btn-primary:disabled {
    background-color: #6d73e5;
    border-color: #6d73e5;
    opacity: 0.65;
    cursor: not-allowed;
}

a {
    text-decoration: none !important;
    color: #4d73e3 !important;
}

a:hover {
    color: #3b5ab5;
}
.footer-text {
    font-size: 0.8rem;
    color: #2f2f2f;
}
.footer-text span {
    color: #4d73e3;
    cursor: pointer;
    text-decoration: underline;
}
</style>
